// @flow
// eslint-disable react/sort-comp
import * as React from 'react'

type Props = {
  text: Array<string> | string,
  maxLength: number,
  open?: boolean,
}

type State = {}

class TruncatedText extends React.Component<Props, State> {
  static displayName = 'TruncatedText'
  static defaultProps = {
    maxLength: 300,
    open: false,
  }

  static truncate(value: string, maxLength: number) {
    if (!maxLength || !value || value.length <= maxLength) return value
    const substr = value.substring(0, maxLength)
    const lastSpace = substr.lastIndexOf(' ')

    return substr.substring(0, lastSpace) + '…'
  }

  /**
   * Count characters across arrays
   */
  static countChars(description?: Array<string> | string): number {
    if (!description) return 0
    if (Array.isArray(description)) {
      return description.reduce((count, val) => count + val.length, 0)
    }
    return description.length
  }

  render() {
    const { text, maxLength, open } = this.props
    if (open) return text

    if (Array.isArray(text)) {
      if (text.length) {
        return TruncatedText.truncate(text[0], maxLength)
      } else return null
    }

    return TruncatedText.truncate(text, maxLength)
  }
}

export default TruncatedText
