// @flow
import React, { Fragment } from 'react'
import styled from 'styled-components'
import type { VideoCardViewModel } from '../../types/VideoCardViewModel'
import { Dialog } from '@headlessui/react'
import VideoCardClosed from '../VideoCardClosed/VideoCardClosed'
import VideoCardOpened from '../VideoCardOpened/VideoCardOpened'

type Props = VideoCardViewModel & { theme?: string }

type State = {
  isOpen: boolean,
}

const StyledCardElementContainer = styled.div`
  top: 0;

  position: fixed;
  z-index: 80;
  overflow-y: auto;
  max-width: 845px;
  overflow-x: visible !important;
  & > div {
    margin-block-start: 6rem;
    position: relative;
    @media (min-width: 768px) {
      margin-inline: 32px;
      margin-block-start: 9rem;
    }
  }
`

const StyledCloseButton = styled.button`
  display: flex;
  justify-content: end;
  margin-block-end: 20px;
  margin-inline: 32px;

  padding: 0;
  width: 100%;
  border: none;
  position: absolute;
  z-index: 10;
  right: 0px;
  top: -52px;

  &:focus-visible {
    outline: none;
  }

  @media (min-width: 768px) {
    height: 32px;
    top: 0;
    right: -52px;
  }
`

const StyledDialog = styled(Dialog)`
  display: flex;
  justify-content: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
`

const StyledDialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0px;
  background-color: black;
  opacity: 0.5;
  z-index: 70;
`

class VideoCard extends React.Component<Props, State> {
  static displayName = 'VideoCard'
  static defaultProps = {}

  player = null

  state = {
    isOpen: false,
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { isSingle, BannerCard } = this.props

    const cardElement = (display: 'embeded' | 'modal') =>
      display === 'embeded' ? (
        <VideoCardClosed
          {...this.props}
          onHandleOpen={this.handleOpen}
          isSingle={isSingle}
          BannerCard={BannerCard}
        />
      ) : (
        <VideoCardOpened {...this.props} />
      )

    return (
      <>
        {cardElement('embeded')}

        <StyledDialog onClose={this.handleClose} open={this.state.isOpen}>
          <StyledDialogOverlay onClick={this.handleClose} />

          <StyledCardElementContainer>
            <div>
              <StyledCloseButton onClick={this.handleClose}>
                <svg width="32px" height="32px" viewBox="0 0 32 32" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#fff"
                    d="M29.4 2.6a2 2 0 0 0-2.8 0L16 13.2 5.4 2.6a2 2 0 0 0-2.8 0 2 2 0 0 0 0 2.8L13.2 16 2.6 26.6a2 2 0 0 0 0 2.8c.4.4.8.6 1.4.6.6 0 1-.2 1.4-.6L16 18.8l10.6 10.6c.4.4 1 .6 1.4.6.4 0 1-.2 1.4-.6.8-.8.8-2 0-2.8L18.8 16 29.4 5.4c.8-.8.8-2 0-2.8Z"
                  />
                </svg>
              </StyledCloseButton>
              {cardElement('modal')}
            </div>
          </StyledCardElementContainer>
        </StyledDialog>
      </>
    )
  }
}

export default VideoCard
