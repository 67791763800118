// @flow
import React, { lazy, Suspense } from 'react'
import type { VideoCardViewModel } from '../../types/VideoCardViewModel'
import styled from 'styled-components'
import { rem } from 'polished'
import { effects } from '../../styles/theme'
import { getLineHeight, getFontSize } from '../../styles/style-helpers'
import consecutive from 'consecutive'
import Heading from '../../components/Heading/Heading'
import { HyphenatedText } from '../HyphenatedText/HyphenatedText'
import TruncatedText from '../TruncatedText/TruncatedText'
import Paragraph from '../../components/Paragraph/Paragraph'

type Props = VideoCardViewModel

type State = {}

class VideoCardOpened extends React.Component<Props, State> {
  static displayName = 'VideoCardOpened'
  static defaultProps = {}

  render() {
    const { video, subjectArea, description, title } = this.props
    const Vimeo = lazy(() => import('../Vimeo/Vimeo'))
    const keyGen = consecutive()
    const hyphenatedTitle = <HyphenatedText text={title} minWordLength={4} />

    const CardModal = styled.div`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: calc(100% - ${rem(20)});
      cursor: pointer;
      border-radius: 8px;
      border-bottom: ${({ transparent, theme }) =>
        transparent ? effects(theme).borderPositive : '0 none'};
      background: ${({ negative, theme }) =>
        negative ? theme.cardNegativeBackground : theme.cardPositiveBackground};
      ${({ transparent }) => (transparent ? `background: transparent` : null)};
      max-height: 70svh;
      overflow-y: auto;
      margin-inline: 32px;
      z-index: 10;
      margin-bottom: 0;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    `

    const StyledWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: 100%;

      padding: ${({ transparent }) =>
        transparent ? `0 0 ${rem(38)}` : `${rem(64)} ${rem(40)} ${rem(40)}`};
      ${({ image }) => (image ? 'border-top: 0;' : '')};

      ${({ image }) =>
        !!image &&
        `
  padding-top: ${rem(48)};
`}

      ${({ theme }) =>
        (theme.name === 'kids' || theme.name === 'lgbt') &&
        `
  padding:  ${rem(24)};
`}

> :last-child {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        padding: 48px;
      }
    `

    const StyledSubtitleWrapper = styled.div`
      margin-bottom: ${rem(16)};

      /* these 3 lines enforce ellipsis effect when text is too wide */
      display: table;
      table-layout: fixed;
      width: 100%;
    `

    const StyledSubtitle = styled.span`
      font-size: ${getFontSize('tiny')};
      font-weight: 600;
      letter-spacing: 0cap.6;
      text-transform: uppercase;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #003851;
      line-height: 16.8px;
    `

    const StyledParagraph = styled.div`
      margin-bottom: ${rem(32)};
      color: ${props => props.theme.text};

      && {
        line-height: ${getLineHeight('normal')};
      }
    `

    return (
      <CardModal link={video?.src}>
        {video && (
          <Suspense fallback={<h1>Loading…</h1>}>
            <Vimeo {...video} controls />
          </Suspense>
        )}

        <StyledWrapper>
          {subjectArea && (
            <StyledSubtitleWrapper>
              <StyledSubtitle
                key={`ListCardPreTitle-${keyGen()}`}
                title={subjectArea}
              >
                {subjectArea}
              </StyledSubtitle>
            </StyledSubtitleWrapper>
          )}

          {title && (
            <Heading
              tag="h3"
              level={3}
              responsiveSize
              style={{
                color: '#003851',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '28px',
                marginBottom: '0.5rem',
              }}
            >
              {hyphenatedTitle}
            </Heading>
          )}

          {description && (
            <StyledParagraph
              dangerouslySetInnerHTML={{ __html: description }}
            ></StyledParagraph>
          )}
        </StyledWrapper>
      </CardModal>
    )
  }
}

export default VideoCardOpened
